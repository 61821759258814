<template>
  <div v-loading="loaders.resource">
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 >Add Success Story</h1>
      <success-story-form
        @storeSuccessStory="storeSuccessStory"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import SuccessStoryForm from "./SuccessStoryForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "StoreSuccessStory",
  components: {BackButton, SuccessStoryForm},
  data () {
    return {
      loaders: {
        resource: false
      },
      subMenuManagerId: ''
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    // On clicking submit, this function is called that call Store Api and create new Success Store and Store it in database
    storeSuccessStory(event) {
      let vm = this
      vm.loaders.resource = true
      var formData = new FormData()
      formData.append('subMenuManagerId', vm.subMenuManagerId)
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(this.$store.getters.getBaseUrl + '/api/content/success-stories/store', formData, {config})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Success Story Created successfully.' })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Success Stories',
            dangerouslyUseHTMLString: true,
            message: message,
          })
        })
        .finally(() => {
          vm.loaders.resource = false
        });
    },

    getSubMenuManagerId() {
      let vm = this
      vm.loaders.resource = true
      const resultIndex = vm.sidebarMenuListFromSubMenuManagers.find(({sub_menu}) => sub_menu.title === 'Success Stories')
      vm.subMenuManagerId = (resultIndex) ? resultIndex.id : ''
      vm.loaders.resource = false
    }
  },

  watch: {
    sidebarMenuListFromSubMenuManagers: function (newVal, oldVal) {
      this.getSubMenuManagerId()
    }
  },

  computed: {
    sidebarMenuListFromSubMenuManagers() {
      return [...this.$store.getters.getSidebarMenuListFromSubMenuManagers]
    }
  },

  mounted() {
    this.getSubMenuManagerId()
  }
}
</script>

<style scoped>

</style>
