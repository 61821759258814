<template>
  <div v-loading="loaders.resources" class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Success Stories</h1>
      <div class="d-flex justify-content-end align-items-center">
        <router-link class="ml-2" :to="{ name: 'StoreSuccessStory'}">
          <el-button class="button-hover-effect btn-secondary-action title-btn-padding button-font">Add New</el-button>
        </router-link>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <el-table
        class="table-responsive table-flush checklist-items-table checklist-table"
        header-row-class-name="thead-light"
        :data="response.resources.data"
        width="100%"
        align="center"
      >
        <el-table-column prop="name" label="NAME" min-width="130">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              <span>{{ scope.row.patent_full_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="Before Image" min-width="130">
          <template slot-scope="scope">
            <img v-if="scope.row.image" :src="scope.row.image" class="rounded image-fit" width="50" height="50" alt=""/>
            <p v-else class="pt-3">No Image</p>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="After Image" min-width="130">
          <template slot-scope="scope">
            <img v-if="scope.row.after_image" :src="scope.row.after_image" class="rounded image-fit" width="50" height="50" alt=""/>
            <p v-else class="pt-3">No Image</p>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="140" min-width="130">
          <template slot-scope="{ $index, row }">
            <div class="d-flex">
              <router-link :to="{name: 'ShowSuccessStory', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
              <router-link :to="{name: 'EditSuccessStory', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
              <img
                class="pointer mr-2"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
                @click="handleDelete($index, row)"
              />
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.resources.meta.total"
          v-model="response.resources.meta.current_page"
          :per-page="parseInt(response.resources.meta.per_page)"
          @input="changePage"
        >
        </custom-pagination>
      </div>
      <div class="modals">
        <remove-modal
          :modal-text="removeModalText"
          :removeId="removeId"
          @onRemove="removeSuccessStory"
        />
      </div>
    </div>
  </div>
</template>

<script>

import CustomPagination from "../../../Components/Pagination/CustomPagination";
import {Table, TableColumn} from "element-ui";

const RemoveModal = () => import(/* webpackChunkName: "surgeryRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "SuccessStories",
  components: {
    CustomPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal
  },
  data () {
    return {
      loaders: {
        resources: false,  //Using for While Loading resources
      },
      response: {
        resources: {
          data: [],
          links: {},
          meta: {},
        },
      },

      params: {
        page: 1,
        paginate: 5,
        subMenuManagerId: ''
      },
      removeId: '',
      removeModalText: ''
    }
  },
  methods: {

    //On requesting delete a success story, this function is called for calling Api and deleting that specific item
    removeSuccessStory() {
      let vm = this
      vm.loaders.resources = true
      const payload = {
        id: this.removeId
      }
      axios.post(this.$store.getters.getBaseUrl + '/api/content/success-stories/destroy', payload)
        .then((response) => {
          this.removeId = '';
          this.fetchSuccessStories();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.resources = false);
    },

    //On Change Page, update the page and fetch the Success Stories of that page
    changePage(page){
      this.params.page = page
      this.fetchSuccessStories()
    },

    //Fetch All the success stories from database
    fetchSuccessStories() {
      let vm = this
      vm.loaders.resources = true
      const config = {
        params: this.params,
      }
      axios.get(this.$store.getters.getBaseUrl + '/api/content/success-stories', config)
        .then((response) => {
          vm.response.resources = response.data;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.resources = false);
    },

    // This function handles the delete request of specific success story
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    // This function prompts the Remove Modal to delete the specific success story
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Surgery.';
      this.removeId = row.id;
    },

    //Get SubMenuManagerId from SideBarMenuList
    getSubMenuManagerId() {
      let vm = this
      vm.loaders.resources = true
      const resultIndex = vm.sidebarMenuListFromSubMenuManagers.find(({sub_menu}) => sub_menu.title === 'Success Stories')
      vm.params.subMenuManagerId = (resultIndex) ? resultIndex.id : ''
      vm.loaders.resources = false
    }
  },

  watch: {
    sidebarMenuListFromSubMenuManagers: function (newVal, oldVal) {
      this.getSubMenuManagerId()
    },

    'params.subMenuManagerId': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchSuccessStories()
      }
    }
  },

  computed: {
    sidebarMenuListFromSubMenuManagers() {
      return [...this.$store.getters.getSidebarMenuListFromSubMenuManagers]
    }
  },

  //getSubMenuManagerId and fetchSuccessStories function will be called so all the data can be get from database on creating
  mounted() {
    this.getSubMenuManagerId()
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-table__row{
  height: 83px;
}

.image-fit{
  object-fit: cover;
}

.el-table{
  padding-top: 80px;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.btn{
  margin: 0;
}

</style>
