<template>
  <div class="mt-4">
    <div class="forms" v-loading="loaders">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <base-input
              :rules="{required: true}"
              name="Name"
              label="Patient Full Name*"
              v-model="request.successStoryForm.patentFullName"
              placeholder="Name">
            </base-input>
            <div class="row mb-4">
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
                <div class="img-upload"
                     :class="{'w-100':!beforeImageExist}"
                >
                  <label class="form-control-label" for="beforePhoto">Before Photo</label>
                  <input type="file" id="beforePhoto" ref="beforeImageRef" accept="image/png, image/gif, image/jpeg"
                         class="form-control mb-1" @change="onBeforeImageChange">
                  <span class="font-size-12px">Recommended image size 500x500 px. Must be less than 500kb.</span>
                </div>
                <el-image
                  v-loading="request.loaders.image"
                  class="img-size pl-2"
                  :class="{'d-none':!beforeImageExist}"
                  :src="request.successStoryForm.oldBeforeImage"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
              <div class="img-upload"
                   :class="{'w-100':!afterImageExist}">
                <label class="form-control-label" for="afterPhoto">After Photo</label>
                <input type="file" id="afterPhoto" ref="afterImageRef" accept="image/png, image/gif, image/jpeg"
                       class="form-control mb-1" @change="onAfterImageChange">
                <span class="font-size-12px">Recommended image size 500x500 px. Must be less than 500kb.</span>
              </div>
                <el-image
                  v-loading="request.loaders.image"
                  class="img-size pl-2"
                  :class="{'d-none':!afterImageExist}"
                  :src="request.successStoryForm.oldAfterImage"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>
            </div>
            <div class="row">
              <div class="col equal-height-columns">
                <label  class="form-control-label">Content*</label>
                <editor
                  v-model="request.successStoryForm.content"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit('successStoryForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          Save
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>

import Helper from '../../../../util/globalHelpers'
import  { store } from "@/vuex/store";

export default {
  name: "SuccessStoryForm",
  props: [ 'storyDataToEdit', 'loaders' ],
  data () {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      request: {
        loaders:{
          image: false,
        },
        successStoryForm: {
          patentFullName: '',
          newBeforeImage: '',
          oldBeforeImage: '',
          newAfterImage: '',
          oldAfterImage: '',
          content: '',
        },
      }
    }
  },
  methods: {

    // On click submit, this function gets called to save the success story data, and redirect based on Edit/Store
    async onSubmit(formName) {
      const isSuccessStoryFormValid = await this.$refs['formValidator'].validate();
      if (isSuccessStoryFormValid) {
        this.request.successStoryForm.content = Helper.addCustomStylingToContent(this.request.successStoryForm.content)
        if (this.$route.name === 'StoreSuccessStory') {
          this.$emit('storeSuccessStory', this.request.successStoryForm);
        } else if (this.$route.name === 'EditSuccessStory') {
          this.$emit('updateSuccessStory', this.request.successStoryForm);
        } else {
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    // This function stores the Before image in data property from input
    onBeforeImageChange(e){
      this.request.successStoryForm.newBeforeImage = e.target.files[0];
    },

    // This function stores the After image in data property from input
    onAfterImageChange(e){
      this.request.successStoryForm.newAfterImage = e.target.files[0];
    },

    handleLoad() {
      this.request.loaders.image = false
    }
  },
  watch: {
    storyDataToEdit(newVal) {
      this.request.successStoryForm.patentFullName = newVal.patent_full_name ?? ''
      this.request.successStoryForm.oldBeforeImage = newVal.image ?? ''
      this.request.successStoryForm.oldAfterImage = newVal.after_image ?? ''
      this.request.successStoryForm.content = newVal.content ?? ''
    }
  },
  computed: {
  beforeImageExist() {
      return this.request.successStoryForm.oldBeforeImage !== ''
        && this.request.successStoryForm.oldBeforeImage
        && this.request.successStoryForm.newBeforeImage === ''
    },
    afterImageExist() {
      return this.request.successStoryForm.oldAfterImage !== ''
        && this.request.successStoryForm.oldAfterImage
        && this.request.successStoryForm.newAfterImage === ''
    }
  }
}
</script>

<style scoped>

.img-size{
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload{
  width: calc(100% - 120px);
  display: inline-block;
}

</style>
