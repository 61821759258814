<template>
  <div v-loading="loaders.resource">
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit Success Story</h1>
      <success-story-form
        @updateSuccessStory="updateSuccessStory"
        :story-data-to-edit="response.data"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import SuccessStoryForm from "./SuccessStoryForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditSuccessStory",
  components: {BackButton, SuccessStoryForm},
  data () {
    return {
      loaders: {
        resource: false
      },
      response: {
        data: ''
      },
      storyId: this.$route.params.id
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    // Fetch the Success Story Based on storyId
    fetchSuccessStory() {
      let vm = this
      vm.loaders.resource = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/success-stories/' + this.storyId)
        .then((response) => {
          vm.response.data = response.data.data
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.resource = false);
    },

    // Call Update Api and update specific Success Story
    updateSuccessStory(event) {
      let vm = this
      vm.loaders.resource = true
      var formData = new FormData()
      formData.append('id', this.storyId)
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(this.$store.getters.getBaseUrl + '/api/content/success-stories/update', formData, {config})
        .then(response => {
          vm.$notify.success({
          title: 'Success',
          message: 'Success Story updated successfully. Always check to see how your changes look in the Baritastic app.' })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Success Stories',
            dangerouslyUseHTMLString: true,
            message: message,
          })
        })
        .finally(() => {
          vm.loaders.resource = false
        });
    },
  },
  created() {
    this.fetchSuccessStory()
  }
}
</script>

<style scoped>

</style>
