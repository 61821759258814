<template>
  <div v-loading="loaders.resource">
    <div class="ml-4 pl-3">
      <back-button/>
    </div>
    <div class="container-fluid d-flex justify-content-between mt-4 mb-4 row">
      <h1 class="ml-3 d-inline-block mt-2">View Success Story</h1>
      <router-link class="router-link pr-2" :to="{name: 'EditSuccessStory' , params: { id: this.storyId } }">
        <base-button type="secondary" class="pt-2 pb-2 font-weight-normal">
          Edit
        </base-button>
      </router-link>
    </div>
    <div class="col-lg-6 col-md-6 content-section-card-width">
      <card class="overflow-auto no-border-card">
        <div>
          <h1 class="contact-detail-heading">{{ response.data.patent_full_name }}</h1>
          <div class="d-flex">
            <div class="pr-4">
              <h5 class="mb-0 pb-1 image-label">Before</h5>
              <img class="rounded image-fit" :src="response.data.image" width="124" height="114" alt="No Image"/>
            </div>
            <div class="pl-4">
              <h5 class="mb-0 pb-1 image-label">After</h5>
              <img class="rounded image-fit" :src="response.data.after_image" width="124" height="114" alt="No Image"/>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="col-lg-6 col-md-6 content-section-card-width">
      <card class="no-border-card">
        <h2>Content</h2>
        <p class="mb-2 overflow-auto story-max-height" v-html="response.data.content"></p>
      </card>
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/Router/BackButton";

export default {
  name: "ShowSuccessStory",
  components: {
    BackButton
  },
  data () {
    return {
      loaders: {
        resource: false
      },
      response: {
        data: ''
      },
      storyId: this.$route.params.id
    }
  },
  methods: {

    // Fetch the Success Story Based on storyId
    fetchSuccessStory() {
      let vm = this
      vm.loaders.resource = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/success-stories/' + this.storyId)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.data = response.data.data;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.resource = false);
    }
  },
  created() {
    this.fetchSuccessStory()
  }
}
</script>

<style scoped>

.image-fit{
  object-fit: cover;
}

.image-label{
  font: normal normal normal 14px/19px Open Sans;
  color: #172B4D;
}

.story-max-height{
  max-height: 400px;
}

</style>
