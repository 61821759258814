import { render, staticRenderFns } from "./ShowSuccessStory.vue?vue&type=template&id=10522fbd&scoped=true&"
import script from "./ShowSuccessStory.vue?vue&type=script&lang=js&"
export * from "./ShowSuccessStory.vue?vue&type=script&lang=js&"
import style0 from "./ShowSuccessStory.vue?vue&type=style&index=0&id=10522fbd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10522fbd",
  null
  
)

export default component.exports